import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import VocT from "./images/VOC-t-min.jpg";
import BeardOil from "./images/beard-oil-min.png";
import SuhT from "./images/suh-t-min.jpg";
import DieTryingT from "./images/die-trying-shirt-min.jpg";
import BuyNowButton from "./images/button.png";
import MetalLogoHat from "./images/metal-hat.png";

import Menu from "./Menu";

const styles = {
  heroContent: {
    color: "white",
    maxWidth: 600,
    margin: "auto",
    padding: "30px",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    // backgroundColor: "#282c2e"
  },
  payButton: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#424547",
    color: "gray",
  },
  hrWidth: {
    width: "85%",
  },
  tabsContainer: {
    width: "85%",
    padding: "20px",
    margin: "auto",
  },
  media: {
    paddingTop: "100%",
    backgroundColor: "gray",
  },
  buyNow: {
    width: "120px",
    padding: "1px",
    "&:hover": {
      padding: "2px",
      background: "gray",
      borderRadius: "10px",
    },
  },
  textColor: {
    color: "white",
  },
  bgColor: {
    backgroundColor: "#424547",
  },
};

function MerchPage(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Menu />
      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Typography
            component="h4"
            variant="h4"
            align="center"
            className={classes.textColor}
            gutterBottom
          >
            Merch
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.textColor}
            component="p"
          >
            Thanks for your interest in my merch! Orders will shipped as soon as
            possible upon purchase! *Shipping fee is $5
          </Typography>
        </div>
        {/* End hero unit */}
        <Grid container spacing={40} className={classes.tabsContainer}>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={MetalLogoHat}
                title="Black Metal Logo Hat"
              />
              <CardContent className={classes.bgColor}>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $20
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="VCSM45RVV2HSG"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={DieTryingT}
                title="Die Trying T"
              />
              <CardContent className={classes.bgColor}>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $20
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="9P2LTFP5W85HU"
                  />
                  <table>
                    <tr>
                      <td>
                        <input type="hidden" name="on0" value="Sizes"></input>
                        <Typography className={classes.textColor}>
                          Select Size
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select
                          name="os0"
                          style={{
                            width: "100px",
                            marginBottom: "8px",
                          }}
                        >
                          <option value="Small">Small </option>
                          <option value="Medium">Medium </option>
                          <option value="Large">Large </option>
                          <option value="XL">XL </option>
                          <option value="2XL">2XL </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardMedia className={classes.media} image={SuhT} title="Suh T" />
              <CardContent className={classes.bgColor}>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $20
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="W5DEW9YBU3FCS"
                  />
                  <table>
                    <tr>
                      <td>
                        <input type="hidden" name="on0" value="Sizes"></input>
                        <Typography className={classes.textColor}>
                          Select Size
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select
                          name="os0"
                          style={{
                            width: "100px",
                            marginBottom: "8px",
                          }}
                        >
                          <option value="Small">Small </option>
                          <option value="Medium">Medium </option>
                          <option value="Large">Large </option>
                          <option value="XL">XL </option>
                          <option value="2XL">2XL </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardMedia className={classes.media} image={VocT} title="VOC T" />
              <CardContent className={classes.bgColor}>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $20
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="6CS3N36FVF9XQ"
                  />
                  <table>
                    <tr>
                      <td>
                        <input type="hidden" name="on0" value="Sizes"></input>
                        <Typography className={classes.textColor}>
                          Select Size
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select
                          name="os0"
                          style={{
                            width: "100px",
                            marginBottom: "8px",
                          }}
                        >
                          <option value="Small">Small </option>
                          <option value="Large">Large </option>
                          <option value="XL">XL </option>
                          <option value="2XL">2XL </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={BeardOil}
                title="Beard Oil"
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $20
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />

              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="E6HZXQR2TUZ8A"
                  />
                  <input
                    className={classes.buyNow}
                    type="image"
                    src={BuyNowButton}
                    // border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt="Beard Oil"
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

MerchPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MerchPage);
