import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Menu from "./Menu";

const styles = {
  heroContent: {
    maxWidth: 600,
    margin: "auto",
    padding: "30px"
  },
  hrWidth: {
    width: "85%"
  },
  text: {
    color: "white"
  }
};

function MusicReturnPage(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Menu />
      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Typography
            className={classes.text}
            component="h2"
            variant="h2"
            align="center"
            gutterBottom
          >
            Thanks!
          </Typography>
          <Typography
            className={classes.text}
            variant="h4"
            align="center"
            component="p"
          >
            Your order will be shipped as soon as possible!
          </Typography>
        </div>
      </main>
    </React.Fragment>
  );
}

MusicReturnPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MusicReturnPage);
