import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Menu from "./Menu";
import YBWTM from "./Youtube/YBWTM";
import BTTR from "./Youtube/BTTR";
import Delirium from "./Youtube/Delirium";
import LineEmUp from "./Youtube/LineEmUp";
import Bracer from "./Youtube/Bracer";
import TheseMoments from "./Youtube/TheseMoments";

const styles = theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: "center",
    backgroundColor: "#424547"
  },
  images: {
    width: "100%",
    height: "450px",
    objectFit: "cover"
  },
  lowerGrid: {
    width: "85%",
    margin: "auto"
  }
});

function VideoPage(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Menu />
      <Grid container spacing={24} className={classes.lowerGrid}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <BTTR />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <YBWTM />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Delirium />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Bracer />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <LineEmUp />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <TheseMoments />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

VideoPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VideoPage);
