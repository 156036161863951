import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Menu from "./Menu";

const styles = {
  heroContent: {
    maxWidth: 600,
    margin: "auto",
    padding: "30px",
  },
  hrWidth: {
    width: "85%",
  },
  text: {
    color: "white",
  },
};

function TabReturnPage(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Menu />
      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Typography
            className={classes.text}
            component="h2"
            variant="h2"
            align="center"
            gutterBottom
          >
            Thanks!
          </Typography>
          <Typography
            className={classes.text}
            variant="h4"
            align="center"
            component="p"
          >
            You'll recieve an email with the Guitar Pro file, .pdf, and .mp3
            shortly!
          </Typography>
        </div>
      </main>
    </React.Fragment>
  );
}

TabReturnPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabReturnPage);
