import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import BuyNowButton from "./images/button.png";

// import DiversionAlbum from "./images/diversion-album.jpg";

import Menu from "./Menu";

const styles = {
  heroContent: {
    maxWidth: 600,
    margin: "auto",
    padding: "30px",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    backgroundColor: "#424547",
  },
  payButton: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#424547",
  },
  hrWidth: {
    width: "85%",
  },
  tabsContainer: {
    width: "85%",
    padding: "20px",
    margin: "auto",
  },
  textColor: {
    color: "white",
    margin: "auto",
    textAlign: "center",
  },
  bgColor: {
    backgroundColor: "#424547",
  },
  buyNow: {
    width: "120px",
    padding: "1px",
    "&:hover": {
      padding: "2px",
      background: "gray",
      borderRadius: "10px",
    },
  },
  modalDetails: {
    position: "absolute",
    width: "80%",
    backgroundColor: "#fff",
    boxShadow: 100,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 5,
    height: "100%",
  },
  emailForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 20,
  },
  modalText: {
    textAlign: "center",
    fontSize: "30px",
    marginBottom: 30,
    marginTop: 100,
  },
};

function Tabs(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Menu />
      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Typography
            component="h4"
            variant="h4"
            align="center"
            className={classes.textColor}
            gutterBottom
          >
            Tabs
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.textColor}
            component="p"
          >
            Thanks for your interest in my music! Tabs are available in both
            Guitar Pro and .pdf versions. Upon purchase both versions will be
            sent along with a mp3 of the song!
          </Typography>
        </div>

        <Grid container className={classes.tabsContainer}>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card
              className={classes.bgColor}
              onClick={() => console.log("clicked")}
            >
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Die Trying
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="SQ65NEKQWR53N"
                  ></input>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  ></img>
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card
              className={classes.bgColor}
              onClick={() => console.log("clicked")}
            >
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Party Goblin
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="57UJSPLVMG8ZN"
                  ></input>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  ></img>
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card
              className={classes.bgColor}
              onClick={() => console.log("clicked")}
            >
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Remission
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                {/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input type="hidden" name="hosted_button_id" value="T3NXFBU8ASFVJ"></input>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"></img>
                </form> */}
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="KAGDB9YQ7S4WA"
                  ></input>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  ></img>
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card
              className={classes.bgColor}
              onClick={() => console.log("clicked")}
            >
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Vice of Choice
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="T3NXFBU8ASFVJ"
                  ></input>
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  ></img>
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card
              className={classes.bgColor}
              onClick={() => console.log("clicked")}
            >
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Remeber to Forget
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="YCTCWGC5LC8U8"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    The No Soul Blues
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="PZDV6NBRUVBP2"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Buy the Ticket, Take the Ride
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="Y6L6SGRH5G3BA"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Diversion
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="9RLXHCB8V73RJ"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Yeah, But Where's the Melody?
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="EUANCEPXPBSCQ"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Delirium
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="YB4QFY8M2UCUQ"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Line 'em Up
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="4FQYGB5TPUVS8"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.tabsContainer}>
            <Card className={classes.bgColor}>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    Bracer
                  </Typography>
                </div>
              </CardContent>
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $5
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="KYENXYDW3SP6G"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tabs);
