import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import DiversionAlbum from "./images/diversion-album-min.jpg";
import DieTryingAlbum from "./images/die-trying-album.jpg";
import VoiceOfChoiceAlbum from "./images/voice-of-choice-album-min.jpg";
import BuyNowButton from "./images/button.png";

import Menu from "./Menu";

const styles = {
  heroContent: {
    color: "white",
    maxWidth: 600,
    margin: "auto",
    padding: "30px",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    // backgroundColor: "#282c2e"
  },
  payButton: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#424547",
    color: "gray",
  },
  hrWidth: {
    width: "85%",
  },
  tabsContainer: {
    width: "85%",
    margin: "auto",
  },
  media: {
    paddingTop: "100%",
    backgroundColor: "gray",
  },
  buyNow: {
    width: "120px",
    padding: "1px",
    "&:hover": {
      padding: "2px",
      background: "gray",
      borderRadius: "10px",
    },
  },
  textColor: {
    color: "white",
  },
  bgColor: {
    backgroundColor: "#424547",
  },
  paddingFix: {
    padding: "20px"
  }
};

function MusicPage(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Menu />
      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Typography
            component="h4"
            variant="h4"
            align="center"
            className={classes.textColor}
            gutterBottom
          >
            Music
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.textColor}
            component="p"
          >
            Thanks for your interest in my music! Signed hard copies are
            available and will shipped as soon as possible upon purchase!
            *Shipping fee is $5
          </Typography>
        </div>
        {/* End hero unit */}
        <Grid container spacing={40} className={classes.tabsContainer}>
          <Grid item xs={12} sm={6} md={4} className={classes.paddingFix}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={DieTryingAlbum}
                title="Die Trying (Pre-Order)"
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $15
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />

              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="DWHP6B5GD7SQJ"
                  />
                  <input
                    className={classes.buyNow}
                    type="image"
                    src={BuyNowButton}
                    // border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.paddingFix}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={VoiceOfChoiceAlbum}
                title="Voice Of Choice"
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $15
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />

              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="M5LR77TN2NMYW"
                  />
                  <input
                    className={classes.buyNow}
                    type="image"
                    src={BuyNowButton}
                    // border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.paddingFix}>
            <Card className={classes.bgColor}>
              <CardMedia
                className={classes.media}
                image={DiversionAlbum}
                title="Diversion"
              />
              <CardContent className={classes.bgColor}>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.textColor}
                  >
                    $15
                  </Typography>
                </div>
              </CardContent>
              <hr className={classes.hrWidth} />
              <Card className={classes.payButton}>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="JRVP6P8ZQUBHQ"
                  />
                  <input
                    type="image"
                    className={classes.buyNow}
                    src={BuyNowButton}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

MusicPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MusicPage);
