import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Menu from "./Menu";
// import ImageSlider from "./ImageSlider";
import YBWTM from "./Youtube/YBWTM";

// import NickPic1 from "./images/COMPLEX.jpg";
// import NickPic2 from "./images/COMPLEX2.jpg";
import NickPic3 from "./images/picture-of-nick-alley-min.jpg";
// import NickPic4 from "./images/pic-of-nick-alley-2.jpg";
// import NickPic5 from "./images/picture-of-nick.jpg";

// import "./main.css";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: "center",
    backgroundColor: "#424547",
  },
  images: {
    width: "100%",
    height: "450px",
    objectFit: "cover",
  },
  lowerGrid: {
    width: "85%",
    margin: "auto",
  },
  paddingFix: {
    padding: "20px"
  }
});

function LandingPage(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Menu />
      <Grid container spacing={24} className={classes.lowerGrid}>
        <Grid item xs={12} sm={6} className={classes.paddingFix}>
          <Paper className={classes.paper}>
            <img src={NickPic3} alt="nick" className={classes.images} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.paddingFix}>
          <Paper className={classes.paper}>
            <YBWTM />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPage);
