import React, { Component } from "react";
import LandingPage from "./LandingPage";
import Tabs from "./Tabs";
import MerchPage from "./MerchPage";
import MusicPage from "./MusicPage";
import VideoPage from "./VideoPage";
import TabReturnPage from "./TabReturnPage";
import MusicReturnPage from "./MusicReturnPage";
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route exact path="/" component={LandingPage} />
          <Route path="/tabs" component={Tabs} />
          <Route path="/tab-return" component={TabReturnPage} />
          <Route path="/music-return" component={MusicReturnPage} />
          <Route path="/music" component={MusicPage} />
          <Route path="/merch" component={MerchPage} />
          <Route path="/videos" component={VideoPage} />
          {/* <Route path="/shows" component={Shows} />
          <Route path="/videos" component={Videos} />
          <Route path="/contact" component={Contact} /> */}
        </div>
      </Router>
    );
  }
}

export default App;
