import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

// import NameLogo from "./images/black-logo-resize.png";
import NameLogoWhite from "./images/black-logo-resize-white.png";

const styles = (theme) => ({
  toolbarSecondary: {
    justifyContent: "center",
  },
  menu: {
    margin: theme.spacing.unit,
    fontSize: "35px",
    justifyContent: "center",
    marginLeft: "10px",
    marginRight: "10px",
  },
  logo: {
    maxWidth: "300px",
    margin: "auto",
    padding: "15px",
    objectFit: "cover",
  },
  hrWidth: {
    width: "85%",
  },

  button: {
    color: "white",
    fontSize: "25px",
    marginLeft: "10px",
    marginRight: "10px",
    "&:hover": {
      background: "gray",
    },
  },
  input: {
    display: "none",
  },
  buttonContainer: {
    textAlign: "center",
  },
});

function Menu(props) {
  const { classes } = props;

  return (
    <div>
      <Toolbar>
        <a href="/" className={classes.logo}>
          <img src={NameLogoWhite} alt="logo" className={classes.logo} />
        </a>
      </Toolbar>
      <hr className={classes.hrWidth} />
      <div className={classes.buttonContainer}>
        <Button href="/tabs" className={classes.button}>
          Tabs
        </Button>
        <Button href="/merch" className={classes.button}>
          Merch
        </Button>
        <Button href="/music" className={classes.button}>
          Music
        </Button>
        <Button href="/videos" className={classes.button}>
          Videos
        </Button>
        {/* <Button onClick={ComingSoon} className={classes.button}>
          Contact
        </Button> */}
      </div>

      <hr className={classes.hrWidth} />
    </div>
  );
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Menu);
